.sectionTestimonial .slick-slider {
  border: 5px solid #23bab5;
  background: white;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .sectionTestimonial .slick-slider {
    max-width: 80% !important;
    margin: 0 auto !important;
    border-radius: 12px;
  }
}

@media (min-width: 1366px) {
  .sectionTestimonial .slick-slider {
    max-width: 1150px !important;
    margin: 0 auto !important;
    border-radius: 12px;
  }
}
@media (max-width: 1024px) {
  .sectionTestimonial .slick-slider {
    height: 400px;
  }
}
.sectionTestimonial .slick-slider .slick-prev:before,
.slick-next:before {
  display: none !important;
}

.sectionTestimonial .slick-slider .slick-prev {
  left: 0px;
}
.sectionTestimonial .slick-slider .slick-next {
  right: 0px;
}
