.offerHero {
  background-color: #443eff;
  background-image: url('/advance-website/assets/images/offer/offer-page-celebrations-desktop.svg');
  background-position: center bottom;
}
@media (max-width: 767px) {
  .offerHero {
    height: 14rem;
  }
}

@media (max-width: 767px) {
  .offerHero {
    height: 14rem;
  }
}

.offerDetailsContainer {
  border-top-width: 1px;
  border-top-color: #e2e8ee;
  border-bottom-width: 1px;
  border-bottom-color: #e2e8ee;
}

.offerOuterContainer {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;
}
.countryOuterContainer {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.boxWhite {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.maxWTiny {
  max-width: 50px;
}

.offerContainer {
  /* margin-top: -50px;
  padding-top: 120px; */
  position: relative;
}

.topImageHolder {
  width: 100%;
  position: absolute;
  top: -50px;
  left: 0px;
}

.topImageHolder img {
  margin-left: auto;
  margin-right: auto;
}

.titleHolder {
  font-weight: 600;
  letter-spacing: -0.2px;
  color: #333333;
  margin-bottom: 14px;
}

.subtitleHolder {
  font-weight: 400;
  line-height: 20px;
  color: #4f4f4f;
}

.interestTextHolder {
  font-weight: 400;
  color: #84848c;
}

.interestHolder {
  font-weight: 600;
  background: #dcf8f7;
  border-radius: 37px;
  line-height: 24px;
  color: #019691;
  padding: 5px 12px 5px 17px;
  margin: 0 1px;
}

.amountHolder {
  font-weight: 700;
  letter-spacing: -1px;
  color: #009df6;
  margin-top: 21px;
}

.maxWidth300 {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.infoBoxHolder {
  background: #f5f7f9;
  border-radius: 6px;
  margin-bottom: 16px;
}

.infoBoxHolderSecondary {
  background: #fefaed;
}

.maxWidth450 {
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
}

.warningText {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #4f4f4f;
  display: inline-block;
}

.warningText.textBase {
  font-size: 15px;
}

.iconHolder {
  margin-right: 14px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: auto;
}

.iconHolder.iconExclamationBlue {
  content: url('/advance-website/assets/images/offer/exclamation-blue.svg');
  vertical-align: top;
}

.iconHolder.iconExclamationYellow {
  content: url('/advance-website/assets/images/offer/exclamation-yellow.svg');
  vertical-align: top;
}

.iconHolder.iconCommentYellow {
  content: url('/advance-website/assets/images/offer/comment-yellow.svg');
  vertical-align: sub;
}

.button.buttonSuccess {
  background: #00b0aa;
}

.button.button.buttonSuccess:hover {
  background: #3dccc7;
}

.disclaimerHolder {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #828282;
}

.congratulations .offerHero {
  background-color: #443eff;
  background-image: none;
}

.congratulations .blockTitle {
  color: #84848c;
  max-width: 100%;
  height: auto;
}

.congratulations .blockContent {
  color: #06051f;
  padding-bottom: 0;
}

.timelineUnit {
  font-size: 15px;
  color: #4f4f4f;
  font-weight: 400;
  line-height: 20px;
  padding-left: 30px;
  border-left-width: 1px;
  border-left-color: #e2e8ee;
  position: relative;
}

.timelineUnit:last-of-type {
  border-left: none;
  padding-bottom: 0;
}

.timelineUnit:before {
  position: absolute;
  left: -9px;
  top: 0;
  border-top-width: 3px;
  border-color: #fff;
  z-index: 1;
  background-color: #fff;
}

.timelineUnit.iconSilhouette:before {
  content: '';
  background-image: url('/advance-website/assets/icons/blue-silhouette.svg');
  background-size: 24px 24px;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;
}

.timelineUnit.icon-inbox:before {
background: url('/advance-website/assets/icons/blue-inbox.svg'); 
}

.timelineUnit.icon-file:before {
  left: -6px;
background: url('/advance-website/assets/icons/green-file.svg');
}

.footerWarningHolder:before {
  /* content: url('/advance-website/assets/icons/logo-light.svg'); */
  width: 28px;
  margin-right: 1rem;
  vertical-align: top;
}

/* mobile responsive styles-*/
/* react-tooltip */
.tooltipContainer {
  border: none !important;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.18) !important;
  border-radius: 5px !important;
}
.tooltipContainer .tooltipInner {
  justify-content: center;
  max-width: 300px;
  max-height: 200px;
  overflow-y: auto;
  padding: 5px;
}

/* offer container white box */
@media only screen and (min-width: 768px) {
  .offerContainer {
    margin-top: -130px;
    padding-top: 200px;
  }
}

/* button container for mobile devices */
@media (max-width: 767px) {
  .btnMobileDevice {
    width: 100%;
  }
  .btnContainerMobileDevices {
    padding: 0px 10px;
  }
}

/* payment processing */
.paymentProcessingLoader {
  min-height: 130px;
}

.timelineUnit.iconInbox:before {
  content: '';
  background-image: url('/advance-website/assets/icons/blue-inbox.svg');
  background-size: 24px 24px;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;
}

.timelineUnit.iconFile:before {
  left: -6px;
  content: '';
  background-image: url('/advance-website/assets/icons/green-file.svg');
  background-size: 24px 24px;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;
}

.footerContainer {
  position: relative;
}

.footerContainer:before {
  content: url('/advance-website/assets/icons/logo-light.svg');
  width: 28px;
  margin-right: 1rem;
  position: absolute;
  top: -40px;
}

@media (min-width: 1023px) {
  .footerContainer:before {
    left: -40px;
    top: 0;
  }
}

.topImageHolder > img {
  margin: 0;
  width: 100%;
}

/************* media queries ************* /

/* mobile devices till 400px width */
@media (min-width: 300px) and (max-width: 400px) {
  .offerContainer {
    padding-top: 80px;
  }
  .topImageHolder {
    top: -100px;
    left: 0;
  }
}

/* mobile devices */
@media (min-width: 401px) and (max-width: 499px) {
  .topImageHolder {
    top: -120px;
    left: 0;
  }

  .offerContainer {
    padding-top: 80px;
  }
}
@media (min-width: 500px) and (max-width: 767px) {
  .topImageHolder {
    top: -120px;
  }
  .offerContainer {
    padding-top: 175px;
  }
}
/* ipad devices */
@media (min-width: 768px) and (max-width: 1024px) {
  .topImageHolder {
    top: -130px;
    left: 0px;
  }

  .offerContainer {
    padding-top: 150px;
  }
  .offerOuterContainer {
    top: -125px;
  }
  .countryOuterContainer {
    top: -125px;
  }
  .titleHolder {
    margin-bottom: 14px;
  }
  .amountHolder {
    margin-top: 21px;
  }
}

@media (min-width: 768px) {
  .infoBoxHolder {
    margin-bottom: 32px;
  }
}

/* desktop devices */
@media (min-width: 1025px) {
  .topImageHolder {
    top: -70px;
    left: 0px;
  }
  .offerContainer {
    padding-top: 35%;
  }
  .offerOuterContainer {
    margin-top: -125px;
  }
  .countryOuterContainer {
    margin-top: -125px;
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  .offerOuterContainer {
    width: 90vw !important;
  }
  .countryOuterContainer {
    width: 100vw !important;
  }
}

/* react-tooltip */
.scheduleImageHolder {
  position: absolute;
  top: -75px;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
}

.scheduleImageHolder > img {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.scheduleContainer {
  padding-top: 120px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 3px 6px #00000029;
}

.scheduleOuterContainer {
  position: absolute;
  top: 120px;
  width: 100%;
}

.scheduleTitleHolder {
  letter-spacing: -0.2px;
  color: #333333;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.scheduleTitleHolder > div {
  font-size: 16px;
}

.scheduleDetailsContainer {
  border-top-width: 1px;
  border-top-color: #e2e8ee;
}

.scheduleApprovedOffer {
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.scheduleInterestQuestion {
  right: -30px;
}

.scheduleDiscussion {
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  font-size: 13px;
  border-bottom-width: 1px;
  border-bottom-color: #e2e8ee;
}

.scheduleTimelineHolder {
  font-size: 14px;
}

.scheduleTimelineUnit {
  font-size: 14px;
  color: #4f4f4f;
  font-weight: 400;
  line-height: 20px;
  padding-left: 30px;
  border-left-width: 1px;
  border-left-color: #e2e8ee;
  position: relative;
}

.scheduleTimelineUnit:last-of-type {
  border-left: none;
  padding-bottom: 0;
}

.scheduleTimelineUnit:before {
  position: absolute;
  left: -9px;
  top: 0;
  border-top-width: 3px;
  border-color: #fff;
  z-index: 1;
  background-color: #fff;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  content: '';
}

.iconSchedule:before {
  background-image: url('/advance-website/assets/icons/blue-silhouette.svg');
}

.iconDownload:before {
  background-image: url('/advance-website/assets/icons/download.svg');
}

.iconUpload:before {
  background-image: url('/advance-website/assets/icons/upload-i20.svg');
}

.iconSign:before {
  background-image: url('/advance-website/assets/icons/sign-contract-blue.svg');
}

.iconRequest:before {
  background-image: url('/advance-website/assets/icons/credit-card.svg');
}

.iconPay:before {
  background-image: url('/advance-website/assets/icons/request-funds.svg');
}

.scheduleLoanInfo {
  margin-top: 850px;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  color: #4f4f4f;
  font-size: 12px;
}

.scheduleLoanInfoDiscussed {
  margin-top: 720px;
}

.scheduleCta {
  font-size: 16px;
}

.scheduleDone {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.scheduleBack {
  position: absolute;
  z-index: 2;
}

.scheduledImageHolder > img {
  width: 50%;
}

.scheduledContainer {
  padding-top: 80px;
}

@media (min-width: 450px) {
  .scheduleImageHolder > img {
    width: 80%;
  }
  .scheduleContainer {
    padding-top: 120px;
  }
  .scheduledImageHolder > img {
    width: 50%;
  }
}

@media (min-width: 550px) {
  .scheduleImageHolder > img {
    width: 75%;
  }
  .scheduleContainer {
    padding-top: 130px;
  }
  .scheduledImageHolder > img {
    width: 50%;
  }
}

@media (min-width: 650px) {
  .scheduleContainer {
    margin-right: auto;
    margin-left: auto;
    /* width: 75%; */
  }
  .scheduleOuterContainer {
    max-width: 575px;
    margin-right: auto;
  }

  .scheduleEmptyContainer {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1023px) {
  .scheduleOuterContainer {
    max-width: 870px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    top: 220px;
  }
  .offerHero {
    height: 20rem;
  }
  .scheduleContainer {
    width: 100%;
    padding-top: 110px;
  }
  .scheduleImageHolder {
    width: 100%;
    top: -190px;
  }
  .scheduleImageHolder > img {
    width: 70%;
  }
  .scheduleTitleHolder {
    font-size: 30px;
  }
  .scheduleTitleHolder > div {
    font-size: 24px;
  }
  .scheduleDetailsContainer {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .scheduleApprovedOffer {
    font-size: 18px;
  }
  .scheduleDiscussion {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .scheduleDiscussion > div:first-child {
    text-align: center;
    font-size: 14px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .scheduleDiscussion > div:nth-child(2) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .scheduleBlockTitle {
    font-size: 20px;
  }
  .scheduleTimelineUnit {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
  .scheduleTimelineUnit:before {
    background-size: 24px 20px;
    width: 24px;
    height: 24px;
  }
  .scheduleLoanInfo {
    margin-top: 900px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .scheduleLoanInfoDiscussed {
    margin-top: 770px;
  }

  .scheduleLoanInfo > div:first-child {
    width: 26px;
    margin-right: 10px;
  }
  .scheduleLoanInfo > div {
    font-size: 16px;
  }
  .scheduleBack {
    left: 0%;
    right: 0%;
    max-width: 870px;
    margin: 0 auto;
    top: 7%;
  }
  .scheduledImageHolder {
    top: -100px;
  }
  .scheduledImageHolder > img {
    width: 30%;
  }
}

.shadowForm {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.RadioSwitchWrapper {
  gap: 15px;
}