.debug-grid-16 {
  background: transparent
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMklEQVR4AWOgCLz/b0epAa6UGuBOqQHOQHLUgFEDnAbcBZ4UGwDOkiCnkIhdgNgNxAYAiYlD+8sEuo8AAAAASUVORK5CYII=)
    repeat top left;
}

.button {
  @apply shadow text-white text-sm leading-none cursor-pointer text-base;
  padding: 1rem 1.6rem 1rem;
  font-weight: 600;
  background-color: #443eff !important;
}

.button:hover {
  @apply bg-primaryLighter;
}

.button-sm {
  padding: 0.6rem 0.65rem 0.5rem;
  font-size: 13px;
  font-weight: 400;
}

@screen md {
  .button {
    @apply text-xl;
    padding: 20px 70px 23px;
    border-radius: 6px;
  }

  .button-sm {
    @apply text-base;
    padding: 9px 20px;
  }
}

:focus {
  outline: none !important;
  border-color: #a0aec0;
  transition: border 300;
}
:disabled {
  cursor: not-allowed !important;
  /* background-color: #e9ecef; */
  /* color: gray; */
}

/* TODO: Get rid of this once we have messages designed */
section h1 {
  margin-top: 0 !important;
}

/* header button changes for after bank account page design */
.active-link {
  position: relative;
}

.active-link::after {
  content: '';
  border: 2px solid #443eff;
  position: absolute;
  bottom: -12px;
  left: 3px;
  width: 95%;
  height: 2px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

#ym-notification {
  max-width: 310px !important;
}
