@import '../components/Layout/style.css';
@import '../components/Index/Testimonials.css';
@tailwind base;
@tailwind utilities;
@tailwind components;

* {
  outline: none;
}
button:focus {
  outline: none;
}

/* to remove icon step increment icon from input field */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.course-info-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 320px;
  width: 600px;
  max-width: 700px;
  position: absolute;
  top: 80%;
}
@media (max-width: 767px) {
  .course-info-box {
    max-width: max-content;
  }
}

.container-white {
  background: #ffffff;
  border-radius: 5px;
}
.green-tick-icon {
  width: 60px;
  height: 60px;
}
.leap-offers-container {
  position: absolute;
  bottom: 20%;
}
.leap-offers-container1 {
  display: flex;
  margin-top: 20px;
}

.leap-offer-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 10px;
  height: 90px;
  padding: 20px;
  margin-top: 8px;
}
.leap-offer-item-img {
  top: -30%;
  background: white;
  border-radius: 100%;
  padding: 10px;
  box-shadow: 0px 0px 6px #00000029;
}

/* ipad view */
@media (min-width: 768px) and (max-width: 1024px) {
  .leap-offers-container {
    padding: 0px 70px;
    bottom: 40%;
  }
  .leap-offer-item {
    height: 100px;
  }
}
/* mobile view */
@media (max-width: 767px) {
  .container-white {
    max-width: 350px;
  }
  .leap-offer-item {
    height: 50px;
    margin-left: 0px;
    margin-bottom: 5px;
  }
  .leap-offer-item-img {
    padding: 5px;
    margin-right: 5px;
  }
  .leap-offer-item-img > img {
    width: 20px;
    height: 20px;
  }
  .green-tick-icon {
    width: 30px;
    height: 30px;
  }
}

/* faq page styles */
.faq-category-section {
  position: absolute;
  bottom: -12%;
  height: 130px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
}

/* custom window width css - start */
@media (max-height: 767px) {
  .faq-category-section {
    bottom: -25%;
  }
}
/* custom window width css - end */

.faq-icon > img {
  width: 50px;
  height: 50px;
}

/* ipad view */
@media (min-width: 768px) and (max-width: 1024px) {
  .faq-category-section {
    grid-gap: 1rem;
  }
}

@media (max-width: 767px) {
  .faq-category-section {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    height: 200px;
    width: 100%;
    bottom: -12%;
    padding: 0 1rem;
  }
  .faq-category-section > div {
    width: 100%;
    height: 100%;
  }
  .faq-icon > img {
    width: 40px;
    height: 40px;
  }
}

.search-container {
  position: absolute;
  top: 30%;
  left: 0%;
  display: flex;
  align-items: start;
}
@media (max-width: 767px) {
  .search-container {
    top: 24%;
  }
}

.faq-box-shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.faq-questions-container {
  width: 100%;
  height: 470px;
  margin: auto;
  margin-top: 140px;
  margin-bottom: 4rem;
}

.faq-questions-container a,
.collapse-questions-container a {
  text-decoration: underline;
  color: #443eff;
}

.faq-questions-container .faq-questions-section {
  border-right: 1px solid #443eff;
  overflow-y: auto;
}

.faq-feedback-section {
  width: 100%;
  height: 130px;
  margin-top: 50px;
}

/*  collapsible css */
.collapse-questions-container {
  @apply mb-6;
}

.collapse-questions-container > .Collapsible > .is-open > .collapse-header {
  background: #e0eaf3 !important;
}
.collapse-questions-container > .Collapsible > .is-closed > .collapse-header {
  border-bottom: 0.5px solid #e8e3e3;
}

.collapse-questions-container
  > .Collapsible
  > .Collapsible__contentOuter
  > .Collapsible__contentInner {
  border: 1px solid #443eff;
  border-top: none;
}

.collapse-questions-container .Collapsible__trigger {
  @apply cursor-pointer relative font-semibold;
  font-size: 14px;
}

.collapse-questions-container .Collapsible__contentInner {
  @apply text-gray-700;
  font-size: 14px;
}

/* how it works page custom css */
.horizontal-divider {
  height: 1.2px;
  width: 95%;
}

@media (max-width: 1024px) {
  .section-ipad-padding {
    padding-left: 1rem;
  }
  .horizontal-divider {
    width: 80%;
  }
}
@media (max-width: 767px) {
  .tab-border-width {
    width: 95% !important;
  }
}

/* viral loop custom css - start*/

#vl_popup {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.vlns .vl-box::before {
  margin: 0px auto !important;
  content: '' !important;
  background: url(/assets/images/company-logo/logo.svg) !important;
  width: 60px !important;
  height: 60px !important;
  display: block !important;
  position: absolute;
  top: 4%;
  left: 45%;
}

.vlns .vl-box {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.vl-heading {
  padding-top: 4rem !important;
}
.vl-leaderboard-heading,
.vl-leaderboard {
  display: none !important;
}
.vl-leaderboard-info {
  margin-top: 10px !important;
}

.vl-body {
  font-weight: bolder !important;
}

.vl-leaderboard-position {
  display: none !important;
}

/* viral loop custom css - end*/

/* bank-page specicic styles */
.slick-dots li button:before {
  font-size: 10px !important;
}
.slick-dots {
  bottom: -5px !important;
}

/* custom -react full page - start*/
.how-works-container {
  position: relative;
}
.how-works-container #banner {
  z-index: 2;
  position: sticky;
}

@media (max-width: 1025px) {
  .how-works-container #banner {
    position: fixed;
    width: 100%;
  }
}

.Navigation {
  display: none;
}

.mobile-custom-container {
  padding-top: 105px;
}

.mobile-custom-container > div {
  height: 75vh;
  background-image: url('/advance-website/assets/images/about/green-grid.svg');
  background-position: center center;
}
/* custom -react full page - end*/

/* Static pages' common styling */
.static-page-content ul {
  list-style: disc;
  margin-bottom: 1rem;
}

.static-page-content a {
  text-decoration: underline;
}

.static-page-content h2 {
  font-size: 20px;
  line-height: 28px;
}

.help-center-content {
  list-style: disc;
}

.help-center-content ul {
  margin: 0;
  padding-left: 20px;
  list-style-type: disc;
}

.help-center-content ul ul {
  list-style-type: circle;
}

.help-center-content ul ul ul {
  list-style-type: square;
}

.help-center-content li {
  margin: 5px 0;
}

.help-center-content a {
  text-decoration: none;
  color: #007bff;
}

.help-center-content a:hover {
  text-decoration: underline;
}

.help-center-content strong {
  font-weight: bold;
}

.help-center-content ol {
  margin: 0;
  padding-left: 20px;
  list-style-type: decimal;
}

.help-center-content ol ol {
  list-style-type: lower-alpha;
}

.help-center-content ol ol ol {
  list-style-type: lower-roman;
}